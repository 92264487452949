import styled from "styled-components";

export const EnterPriseMain = styled.div`
  position: relative;
  overflow: hidden;
  @media (max-width: 430px) {
    height: calc(100vh - 380px);
  }
  @media (max-width: 414px) {
    height: calc(100vh - 280px);
  }
  @media (max-width: 414px) {
    height: calc(100vh - 180px);
}
  @media (max-width: 375px) {
    height: calc(100vh - 100px);
  }
`;
export const SkeletonEnterPriseBackBg = styled.div`
  background-color:#f1f1f1;
  width: calc(6 / 12 * 100%);
  margin: 0 0 0 0;
  padding: 0;
  border: 0 solid;
  top: 0;
  right: 0;
  z-index: 0;
  height: 100% !important;
  min-height: 50vh;
  max-width: 100%;
  background-size: cover;
  background-attachment: scroll !important;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute !important;
  box-sizing: border-box;
  &.loading-page {
    background-image: none;
  }
  @media (max-width: 600px) {
    margin-top:0px !important;
    background-color:#f1f1f1;
    top: 235px;
    height: 288px !important;
    min-height: 288px !important;
    width: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center top;
  }
`;
export const EnterPriseBackBg = styled.div`
  background-image: ${({ loaded }) =>
    loaded
      ? 'url("https://d31kzidzn6t9lk.cloudfront.net/filters:quality(40)/itservicesindia/HomePageHeadImg-compress02.webp")'
      : "none"};
      background-color:#f1f1f1;
  width: calc(6 / 12 * 100%);
  margin: 0;
  padding: 0;
  border: 0 solid;
  top: 0;
  right: 0;
  z-index: 0;
  height: 100% !important;
  min-height: 50vh;
  max-width: 100%;
  background-size: cover;
  background-attachment: scroll !important;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute !important;
  box-sizing: border-box;

  @media (max-width: 1351px) {
    background-image: ${({ loaded }) =>
      loaded
        ? 'url("https://d31kzidzn6t9lk.cloudfront.net/filters:quality(40)/675x840/itservicesindia/HomePageHeadImg-compress02.webp")'
        : "none"};
  }

  @media (max-width: 600px) {
    background-image: ${({ loaded }) =>
      loaded
        ? 'url("https://d31kzidzn6t9lk.cloudfront.net/filters:quality(40)/fit-in/412x288/itservicesindia/HomePageHeadImg-mobile-1-5x.webp")'
        : "none"};
    top: 235px;
    height: 288px !important;
    min-height: 288px !important;
    width: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center top;
  }
`;

export const EnterPriseSub = styled.div`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 100px);

  &.CRO-Sub-Header-Main{
    min-height: calc(120vh - 100px);
  }
  &.loading-page {
    opacity: 0;
  }
  @media (max-width: 1024px) {
    min-height: calc(50vh - 100px);
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 600px) {
    min-height: unset;
    overflow: hidden;
    margin: 0 -16px;
    gap: 40px;
  }
`;

export const EnterPriseLeft = styled.div`
  //padding: 15px;
  margin-top: 1rem;
  width: 50%;
  @media (max-width: 991px) {
    width: 50%;
    padding-right: 50px;
  }
  @media (max-width: 991px) {
    padding-right: 10px;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0 16px;
    margin-top: 2rem;
    text-align: center;
  }
`;

export const EnterPriseLeftP = styled.p`
  font-size: 24px;
  margin-bottom: 0.7rem;
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
  &.EnterPrise-text {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 1rem;
    font-weight: 400;
  }

  @media (max-width: 820px) {
    font-size: 18px;
    &.EnterPrise-text {
      width: 100%;
      font-size: 14px;
      margin-bottom: 0rem;
    }
    
  }
  @media (max-width: 600px) {
    &.CRO-text-one{
      font-size: 12px;
    }
  }
`;

export const EnterPriseLeftH1 = styled.h1`
  font-size: 46px;
  font-weight: 700;
  line-height: 51px;
  font-family: 'Poppins';
  @media (max-width: 1351px) {
    font-size: 32px;
    line-height: 37px;
  }
  @media (max-width: 820px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    line-height: 35px;
  }
`;

export const EnterPriseRight = styled.div`


  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 991px) {
    //position: relative;
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 0rem;
    position: relative;
    top: 0;
    min-height: 326px;
    img {
      width: 100%;
      height: auto;
      max-height: 326px;
      object-fit: cover;
      position: relative;
      object-position: 100% 65%;
      ${
        "" /* -webkit-transform: scaleX(-1);
      transform: scaleX(-1); 
      scale: 1.11;*/
      }
    }
    &.Brand-Strategy-Header-Right{
      height: auto;
    }
  }
`;
export const EnterPriseRightCol = styled.div`
  overflow: hidden;
`;
export const EnterPriseImg = styled.img`
  @media (max-width: 600px) {
  }
`;

export const CompanyLogoMain = styled.div``;
export const BoxShowdowLeft = styled.div`
  position: absolute;
    top: 0;
    left: 0px;
    height: 100%;
    width: 125px !important;
    background: linear-gradient(to right, rgb(255 255 255) 10%, rgba(255, 255, 255, 0) 90%);
    transition-timing-function: cubic-bezier(0.2,0.8,0.4,1);
    z-index: 999;

  @media (max-width: 820px) {
    width: 20px !important;
  }
`;
export const BoxShowdowRight = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  width: 125px !important;
  background: linear-gradient(
    to left,
    rgb(255, 255, 255) 10%,
    rgba(255, 255, 255, 0) 90%
  );
  transition-timing-function: cubic-bezier(0.2, 0.8, 0.4, 1);
  z-index: 999;

  @media (max-width: 820px) {
    width: 20px !important;
  }
`;
export const CompanyLogoSub = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  @media (min-width: 1600px) {
    padding-top: 160px;
    padding-bottom: 160px;

    &.HomeLogo {
      padding-top: 370px;
    }
  }
  @media (min-width: 1440px) {
    padding-top: 160px;
    padding-bottom: 160px;

    &.HomeLogo {
      padding-top: 350px;
    }
  }
  @media (max-width: 1200px) {
    &.HomeLogo {
      padding-top: 370px;
    }
  }
  @media (max-width: 1024px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media (max-width: 820px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @media (max-width: 600px) {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
`;

export const CompanyLogoH6 = styled.h2`
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 0px;
  letter-spacing: -0.5px;
  @media (max-width: 479px) {
    margin-top: 0rem;
    letter-spacing: -0.5px;
  }
  @media (max-width: 390px) {
    &.EngagementLogoTitle {
      font-size: 15px;
      line-height: 25px;
      letter-spacing: -0.5px;
    }
  }
`;

export const CompanyLogoP = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 2.5rem;
`;

export const ExpertMindsMain = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
`;

export const ExpertMindSub = styled.div`
  display: flex;
  gap: 2.5rem;

  @media (max-width: 991px) {
  }

  @media (max-width: 820px) {
    gap: 1rem;
    justify-content: space-between;
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const ExpertMindsLeft = styled.div`
  width: 35%;
  @media (min-width: 1440px) {
    width: 45%;
  }
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 991px) {
    width: 35%;
  }
  @media (max-width: 820px) {
    width: 45%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const MindsLeftP = styled.p`
  font-size: 20px;
  margin-bottom: 1rem;
  font-family: 'Poppins';
  &.ExpertMindsText {
    font-size: 14px;
    line-height: 1.25;
    margin-top: 0rem;
  }
  @media (min-width: 1440px) {
    font-size: 26px;
        font-family: 'Poppins';
        font-weight: 400;
        margin-bottom: 0rem;
    &.ExpertMindsText {
      font-size: 16px;
    }
  }
  @media (max-width: 991px) {
    br {
      display: none;
    }
  }
`;

export const MindsLeftH1 = styled.h2`
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 28px;
  @media (min-width: 1440px) {
    font-size: 58px;
    line-height: 63px;
    font-family: 'Poppins';
    font-weight: bold;
    margin-bottom: 2rem;
  }
  @media (max-width: 991px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const MindsLeftButton = styled.div`
  & button.btn.btn-secondary {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    border-radius: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    svg.iconify.iconify--solar {
      margin-inline-start: 0.75rem;
      margin-left: 1rem !important;
    }
  }
`;

export const ExpertMindsRight = styled.div`
  width: 65%;

  @media (min-width: 1440px) {
    width: 55%;
  }
  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    width: 65%;
  }
  @media (max-width: 820px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ExpertMindsRightSub = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;

  .RightMain:nth-child(1) {
    margin-top: -10rem;
  }
  .RightMain:nth-child(2) {
    margin-top: 7rem;
  }
  .RightMain:nth-child(3) {
    margin-top: -17rem;
  }
  .RightMain:nth-child(4) {
    margin-top: 0rem;
  }
  .RightMain:nth-child(5) {
    margin-top: -17rem;
  }
  .RightMain:nth-child(6) {
    margin-top: 0rem;
  }
  .RightMain:nth-child(7) {
    margin-top: -17rem;
  }
  .RightMain:nth-child(8) {
    margin-top: 0rem;
  }
  @media (min-width: 1440px) {
    gap: 3.5rem;
    .RightMain:nth-child(1) {
      margin-top: -6rem;
    }
    .RightMain:nth-child(2) {
      margin-top: 7rem;
    }
    .RightMain:nth-child(3) {
      margin-top: -14rem;
    }
    .RightMain:nth-child(4) {
      margin-top: 0rem;
    }
    .RightMain:nth-child(5) {
      margin-top: -14rem;
    }
    .RightMain:nth-child(6) {
      margin-top: 0rem;
    }
    .RightMain:nth-child(7) {
      margin-top: -14rem;
    }
    .RightMain:nth-child(8) {
      margin-top: 0rem;
    }
  }
  @media (max-width: 1024px) {
    gap: 1.5rem;
    .RightMain:nth-child(3) {
      margin-top: -20rem;
    }
    .RightMain:nth-child(5) {
      margin-top: -22.5rem;
    }
    .RightMain:nth-child(7) {
      margin-top: -22rem;
    }
  }
  @media (max-width: 932px) {
    justify-content: flex-end;

    .RightMain:nth-child(1) {
      margin-top: -14rem;
    }
    .RightMain:nth-child(3) {
      margin-top: -20rem;
    }
    .RightMain:nth-child(5) {
      margin-top: -22.5rem;
    }
    .RightMain:nth-child(7) {
      margin-top: -22rem;
    }
  }
  @media (max-width: 820px) {
    gap: 1.5rem;

    .RightMain {
      margin-top: 0 !important;
    }
  }
  @media (max-width: 600px) {
    .RightMain {
      margin-top: 0 !important;
    }
  }
`;

export const RightMain = styled.div`
  text-decoration: unset;
  width: 260px;
  min-height: 310px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-width: 0.5px;
  border-radius: 25px;
  border-color: var(--border-color);
  box-shadow: 0 0.125rem 14px rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  justify-content: center;

  img{
    margin-top: 1.5rem;
  }
  @media (min-width: 1440px) {
    width: 282px;
    min-height: 330px;
  }

  @media (max-width: 1024px) {
    width: 250px;
  }
  @media (max-width: 991px) {
    width: 214px;
  }
  @media (max-width: 820px) {
    width: 100%;
    padding: 1.5rem;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const RightMainImg = styled.img`
  margin-top: 1.5rem;
`;

export const RightMainH3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family:'Poppins';
`;

export const RightMainP = styled.p`
  margin-bottom: 2.5rem;
  font-weight: 300;
`;

export const VideoMain = styled.div`
  padding-top: 10rem;
  padding-bottom: 10rem;
`;

export const VideoSub = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .Video-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }
`;

export const VideoTop = styled.div``;

export const VideoH2 = styled.h2`
  font-size: 36px;
  line-height: 50px;
  font-weight: 600;
  text-align: center !important;
`;

export const MotionVideo = styled.video`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Source = styled.source``;

export const CoreServicesMain = styled.div`
  margin-top: 10rem;
  padding-bottom: 15rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
  @media (max-width: 600px) {
    margin-top: 0;
    padding-bottom: 9rem;
  }
`;

export const CoreServicesSub = styled.div``;

export const CoreServicesTop = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2.5rem;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @media (max-width: 600px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export const CoreServicesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  @media (max-width: 430px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const CoreServicesCol = styled.div`
  width: 70%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const CoreServicesTopP = styled.p`
  font-size: 16px;
  line-height: 2;

  @media (max-width: 430px) {
    text-align: center;
  }
`;

export const CoreServicesTopH2 = styled.h3`
  font-weight: 700;
  font-size: 32px;
  max-width: 650px;
  line-height: 1.25;
  @media (max-width: 820px) {
    font-size: 20px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  @media only screen and (max-width: 950px) and (max-height: 500px) {
    text-align: left;
  }
`;

export const CoreServicesBtn = styled.div`
  @media (max-width: 820px) {
    margin-top: 2rem;
  }

  button.bg-black {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 5rem;
    border: none;
    width: max-content;
  }
`;

export const CoreServicesBottom = styled.div``;

export const CoreServicesSlider = styled.div``;

export const CoreServicesCards = styled.div`
  padding: 1.5rem;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  background-color: var(--background-color);
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  min-height: 380px;
  width: 404px;
  user-select: text;

  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 820px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    min-height: 315px;
  }
  @media (max-width: 736px) {
    min-height: 315px;
  }
  @media (max-width: 479px) {
    width: 100%;
    margin-right: 0rem !important;
    margin-left: 0rem !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
    flex-wrap: wrap;
    text-align: left;
    flex-direction: column;
    svg {
      width: 35px;
    }
  }
`;

export const CardsImg = styled.img`
  margin-top: 1.5rem !important;
`;

export const CardH3 = styled.h3`
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  max-width: 250px;
  @media (max-width: 820px) {
    font-size: 21px;
    line-height: 32px;
    max-width: 200px;
  }
`;

export const CardP = styled.p`
  margin-bottom: 2.5rem;
  @media (max-width: 820px) {
    font-size: 13px;
  }
`;

export const CardSpan = styled.span`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;

  .plusIcon {
    color: #58595c;
  }
`;

export const SquaresDesignMain = styled.div`
  overflow: hidden;
`;

export const SquaresDesignSub = styled.div`
  transform-origin: center;
`;

export const SquaresDesignDiv = styled.div``;

export const RecommendedMain = styled.div`
  @media (max-width: 768px) {
    padding-top: 10rem;
  }
`;

export const RecommendedH1 = styled.h1`
  font-size: 58px;
  font-weight: 600;
  line-height: 71px;
  @media (max-width: 820px) {
    font-size: 38px;
    line-height: 50px;

    br {
      display: none;
    }
  }
`;

export const RecommendedLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RecommendedCards = styled.div`
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
  color: ${(props) => (props.color ? props.color : `#fff`)};
  --tw-border-opacity: 1;
  border-color: rgb(238 238 238);
  border-radius: 16px;
  max-width: 680px;
  padding: 3.5rem;
  margin-bottom: 1rem !important;
  border-width: 1px !important;

  &.black {
    background: black;
    color: white;
  }
`;

export const RecommendedCardsP = styled.p`
  font-family: Merriweather !important;
  line-height: inherit;
`;

export const RecommendedCardsFooter = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 3rem !important;
  flex-direction: row !important;
`;

export const CardsFooterLeft = styled.div`
  column-gap: 1rem;
  align-items: center;
  display: flex;
  flex-direction: row !important;
`;

export const CardsFooterRight = styled.div`
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  flex-wrap: nowrap;
`;

export const RecommendedImg = styled.img``;

export const RecommendedFounder = styled.div`
  flex-direction: column;
  display: flex;
`;

export const FounderP1 = styled.p`
  font-size: 12px;
  font-weight: 500;
`;

export const FounderP2 = styled.p`
  font-size: 12px;
  color: rgb(115 115 115);
`;

export const VerifyP = styled.p`
  font-size: 11px;
`;

export const VerifySpan = styled.span`
  font-weight: 600;
  margin-left: 2px;
`;

export const RecommendedRight = styled.div``;

export const RecommendedRightLast = styled.div`
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-width: 1px;
  max-width: 560px;
  border-radius: 16px;
  background: #1d1e22;
  color: #ffffff;
`;

export const RightLastCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  flex-direction: row;
`;

export const RightLastCardLeft = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RightLastCardRight = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const FaqMain = styled.div`
  padding-top: 9rem;
  padding-bottom: 9rem;
  width: 100%;
  height: 100%;
`;

export const FaqSub = styled.div`
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  margin-bottom: 2.5rem;
  border-radius: 14px;
  border: 1px solid var(--border-color);

  .FaqDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (max-width: 820px) {
    .FaqDiv {
      flex-wrap: wrap;
    }
  }
`;

export const FaqLeft = styled.div`
  position: relative;
  width: 40%;

  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const FaqH2 = styled.h2`
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  @media (max-width: 820px) {
    br {
      display: none;
    }
  }
  @media (max-width: 430px) {
    font-size: 20px;
  }
`;

export const FaqSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 140px;
  bottom: -5px;

  &.saas {
    right: 51%;
  }
  &.screen {
    right: 26%;
  }
  &.ofThe {
    right: 77%;
    top: 55px;
  }

  @media (max-width: 11366px) {
    &.screen {
      right: 20%;
    }
    &.ofThe {
      right: 74%;
      top: 55px;
    }
  }
  @media (max-width: 1180px) {
    &.saas {
      right: 40%;
    }
    &.screen {
      right: 68%;
    }
    &.ofThe {
      right: 67%;
    }
  }

  @media (max-width: 820px) {
    &.saas {
      right: 73%;
    }
    &.screen {
      right: 44%;
    }
  }
  @media (max-width: 430px) {
    &.saas {
      right: 14%;
    }
    &.screen {
      right: 44%;
    }
    &.ofThe {
      right: 35%;
      top: 30px;
    }
  }
  @media (max-width: 414px) {
    &.screen {
      right: 64%;
    }
  }
  @media (max-width: 390px) {
    &.saas {
      right: 8%;
    }
    &.screen {
      right: 33%;
    }
    &.ofThe {
      right: 15%;
      top: 0px;
    }
  }
`;

export const FaqRight = styled.div`
  width: 40%;
  @media (max-width: 820px) {
    width: 100%;
    margin-top: 1rem;
  }
`;

export const FaqP = styled.p`
  font-size: 20px;
  font-weight: 100;
  text-align: left;
`;

export const OrderInner = styled.div`
  color: #9c9c9c;
  font-size: 11px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  position:relative;
`;

export const MarqueeContainerMb = styled.div`
  ${
    "" /* width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;

  @media (max-width: 320px) {
    width: 60%;
  } */
  }
`;

export const MarqueeDiv = styled.div`
  display: flex;
  gap: 20px;
  animation: marquee 200s linear infinite;
  width: max-content;

  span {
    display: inline-block;
    min-width: max-content;
  }
  &.rfm-marquee-container span:last-child img {
  margin-left: 100px;
}
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;
export const MarqueeDivRev = styled.div`
  display: flex;
  gap: 20px;
  animation: marqueerev 200s linear infinite;
  width: max-content;
  padding: 30px 0;
  span {
    display: inline-block;
    min-width: max-content;
  }

  @keyframes marqueerev {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;
